import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Card,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Col,
    Row,
    Button
} from 'reactstrap';
import ColoredScrollbars from '../../common/js/coloredScrollbars';
import 'react-checkbox-tree/src/scss/react-checkbox-tree.scss';
import 'react-checkbox-tree/src/scss/react-checkbox-tree.scss';
import Include from './checkboxTree/include';
import Exclude from './checkboxTree/exclude';
import { setExcludeCheckboxLength, setIncludeCheckboxLength } from '../redux/reducer';
import { logEventForRequestData } from '../apiclient';
import { clearAllMaterialFilter, selectAllMaterialFilter, updateCategoryUsageTypeFilter, updateClearAllOptions, updateFilterItems, updateIncludeExcludeCheckbox, updateMaterialFilterItem, updatePrimaryCategoriesOnlyFlag, updateSelectAllFavourites, updateSelectAllOptions, updateTextInputFilter } from './redux/reducer';
import RequestDataModal from './requestDataModal';
import _, { cloneDeep } from 'lodash';

const IncludeExcludeGrid = (props) => {
    const [expandedValue, setExpandedValue] = useState('');
    const [filterText, setFilterText] = useState('');
    const [includeChecked, setIncludeChecked] = useState([]);
    const [excludeChecked, setExcludeChecked] = useState([]);
    const [filteredNodes, setFilteredNodes] = useState(props?.filterData);
    const [showExpandAll, setShowExpandAll] = useState(expandedValue);
    const [includedropdownOpen, setincludedropdownOpen] = useState(false);
    const toggleInclude = () => setincludedropdownOpen((prevState) => !prevState);
    const [excludedropdownOpen, setexcludedropdownOpen] = useState(false);
    const toggleExclude = () => setexcludedropdownOpen((prevState) => !prevState);
    const [allIncludeChecked, setAllIncludeChecked] = useState([]);
    const [allExcludeChecked, setAllExcludeChecked] = useState([]);
    const [requestDataModal, setRequestDataModal] = useState(false)
    const [expandValues, setExpandValues] = useState([]);
    const [allSubNodes, setAllSubNodes] = useState('');
    const [includeStagesFilteredNodes, setIncludeStagesFilteredNodes] = useState(props?.filterData);
    const [excludeStagesFilteredNodes, setExcludeStagesFilteredNodes] = useState(props?.filterData);
    const dispatch = useDispatch();
    const filter = useSelector((state) => state.filter)
    const searchPersist = useSelector((state) => state.searchPersist)
    const settings = useSelector((state) => state.settings)
    const [isAnyIncludeItemSelected, setIsAnyIncludeItemSelected] = useState(false)
    const [isAnyExcludeItemSelected, setIsAnyExcludeItemSelected] = useState(false)
    const [allIncludeItemsSelected, setAllIncludeItemsSelected] = useState(true)
    const [allExcludeItemsSelected, setAllExcludeItemsSelected] = useState(true)
    const {
        search_container,
    } = useSelector((state) => {
        return {
            search_container: state.search
        }
    });

    useEffect(() => {
        if (props?.isClearAll) {
            setIncludeChecked([])
            setExcludeChecked([])
            handleCustomCheckbox(false, false, "", 'includeClearAll')
            handleCustomCheckbox(false, true, "", 'excludeClearAll')
        }
    }, [props?.isClearAll])
    const onFilterChange = (e) => {
        props.handleSearchText();
        setFilterText(e.target.value);
    };

    const updateFilterDataBasedOnInputValue = (value) => {
        //devType
        if (props.filterType === 'project_development_types') {
            dispatch(updateTextInputFilter({
                'text': value,
                'filter': 'developmentTypes',
                'child': null,
                isIncludeExclude: true,
                includeKey: 'includeDevelopmentTypes',
                excludeKey: 'excludeDevelopmentTypes'
            }));
        }
        //stages
        if (props.filterType === 'project_stages') {
            dispatch(updateTextInputFilter({
                'text': value,
                'filter': props?.dataMappings ? props?.dataMappings?.filterType : '',
                'child': 'children'
            }));
            dispatch(updateTextInputFilter({
                'text': value,
                'filter': 'advanceExcludeStages',
                'child': 'children'
            }));
        }
        // roles & category
        if (props.filterType === 'categories' || props.filterType === 'project_roles') {
            dispatch(updateTextInputFilter({
                'text': value,
                'filter': props?.dataMappings ? props?.dataMappings?.filterType : '',
                'child': props?.dataMappings ? props?.dataMappings?.subFilterType : '',
                isIncludeExclude: props?.dataMappings?.isIncludeExclude,
                includeKey: props?.dataMappings?.includeKey,
                excludeKey: props?.dataMappings.excludeKey
            }));
        }
        // material
        if (props.filterType === "project_materials") {
            dispatch(updateTextInputFilter({
                'text': value,
                'filter': 'materials',
                'child': 'subParent',
                'subChild': 'children',
                'isIncludeExclude': false
            }));
        }
        // project/company regions
        if (props.filterType === "project_geocode" || props.filterType === "company_geocode") {
            dispatch(updateTextInputFilter({
                'text': value,
                'filter': props.regionType,
                'child': 'counties'
            }));
        }
    }

    const filterTree = (value) => {
        if (value.length < 1) {
            if (props.filterType === 'project_stages') {
                setIncludeStagesFilteredNodes(props?.filterData)
                setExcludeStagesFilteredNodes(props?.filterData)
            } else {
                setFilteredNodes(props?.filterData);
            }
        } else {
            if (props.filterType === 'project_stages') {
                const includeNodes = includeStagesFilteredNodes?.length > 0 ? includeStagesFilteredNodes?.reduce(filterNodes, []) : props?.filterData?.reduce(filterNodes, [])
                setIncludeStagesFilteredNodes(includeNodes);
                const excludeNodes = excludeStagesFilteredNodes?.length > 0 ? excludeStagesFilteredNodes?.reduce(filterNodes, []) : props?.filterData?.reduce(filterNodes, [])
                setExcludeStagesFilteredNodes(excludeNodes);
            } else {
                const nodes = filteredNodes?.length > 0 ? filteredNodes?.reduce(filterNodes, []) : props?.filterData?.reduce(filterNodes, []);
                setFilteredNodes(nodes);
            }
        }
    };

    const filterNodes = (filtered, node) => {
        const children = (node.children || []).reduce(filterNodes, []);
        if (
            node.label.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1 ||
            children.length
        ) {
            filtered.push({ ...node, children });
        }

        return filtered;
    };

    const handleClose = (event) => {
        props.handleSearchText();
        setFilterText('');
        updateFilterDataBasedOnInputValue('')
        if (props.filterType === 'project_stages') {
            setIncludeStagesFilteredNodes(props?.filterData)
            setExcludeStagesFilteredNodes(props?.filterData)
        } else {
            setFilteredNodes(props?.filterData);
        }
    }

    const expandValue = (data) => {
        setExpandedValue(data)
    }

    const handleCustomCheckbox = (selectStatus, isExcluded, event, type) => {
        let filterKey = props.dataMappings ? isExcluded ? props.dataMappings?.excludeKey : props.dataMappings?.includeKey : ''
        let checkedIncluded =  [...includeChecked];
        let checkedExcluded = [...excludeChecked]
        if (type === 'includeSelectAll') {
            setAllExcludeChecked([]);
            setExcludeChecked([]);
            const nodes = props.filterType === 'project_stages' ? includeStagesFilteredNodes : filteredNodes
            nodes?.forEach((parentVal) => {
                setAllIncludeChecked(oldArray => [...oldArray, parentVal.value]);
                setIncludeChecked(oldArray => [...oldArray, parentVal.value]);
                checkedIncluded.push(parentVal.value)
                if (parentVal && parentVal.children !== undefined) {
                    parentVal.children.forEach((childVal) => {
                        setAllIncludeChecked(oldArray => [...oldArray, childVal.value]);
                        setIncludeChecked(oldArray => [...oldArray, childVal.value]);
                        checkedIncluded.push(childVal.value)
                        if (childVal && childVal.children !== undefined) {
                            childVal.children.forEach((subChildVal) => {
                                setAllIncludeChecked(oldArray => [...oldArray, subChildVal.value]);
                                setIncludeChecked(oldArray => [...oldArray, subChildVal.value]);
                                checkedIncluded.push(subChildVal.value)
                            });
                        }
                    });
                }
            });
            if (props.filterType === "project_geocode" || props.filterType === "company_geocode") {
                dispatch(updateSelectAllOptions({ 'filter': props.regionType, 'child': 'counties', "selectedData": includeChecked }))
            }
            else if (props.filterType === "project_materials") {
                if (filter?.filtersState[searchPersist.appliedContext]?.materials?.options.every(item => item.display)) {
                    dispatch(selectAllMaterialFilter(true))
                }
                else {
                    let payload = {
                        value: filter?.filtersState[searchPersist.appliedContext]?.materials?.options.filter(item => item.display).map(item => item.value),
                        active: true,
                        selectedData: includeChecked,
                        type: type

                    }
                    dispatch(updateMaterialFilterItem(payload))
                }

            }
            else {
                dispatch(updateSelectAllOptions({
                    isExcluded, 'filter': filterKey, 'child': props?.dataMappings ? props?.dataMappings?.subFilterType : '',
                    isIncludeExclude: props?.dataMappings?.isIncludeExclude, includeKey: props?.dataMappings?.includeKey, excludeKey: props?.dataMappings?.excludeKey, "selectedData": includeChecked
                }))
            }
        }
        if (type === 'includeClearAll' || type === 'excludeClearAll') {
            const nodes = props.filterType === 'project_stages' ? type === 'includeClearAll' ?
                includeStagesFilteredNodes : excludeStagesFilteredNodes : filteredNodes
            const extractedValues = extractValues(nodes);
            setAllIncludeChecked([]);
            setAllExcludeChecked([]);
            // Filter out the values from includeChecked that are not present in newChecked
            const checkedValues = type === 'includeClearAll' ? checkedIncluded : checkedExcluded
            const valuesToAdd = extractedValues.filter(value => !checkedValues.includes(value));
            if (!(filterText.length > 0 && valuesToAdd.length > 0)) {
                type === 'includeClearAll' ? setIncludeChecked([]) : setExcludeChecked([])
                checkedIncluded = []
                checkedExcluded = []
                if (filterKey === 'includeCategories') {
                    dispatch(updateCategoryUsageTypeFilter({ isSelected: false, value: [] }))
                }
                if (props.filterType === "project_geocode" || props.filterType === "company_geocode") {
                    dispatch(updateClearAllOptions({ 'filter': props.regionType, 'child': 'counties' }))
                } else if (props.filterType === "project_materials") {
                    dispatch(clearAllMaterialFilter(false))
                } else {
                    dispatch(updateClearAllOptions({
                        isExcluded, 'filter': filterKey, 'child': props.dataMappings ? props.dataMappings.subFilterType : '',
                        isIncludeExclude: props.dataMappings.isIncludeExclude, includeKey: props.dataMappings.includeKey,
                        excludeKey: props.dataMappings.excludeKey
                    }))
                }
            } else {
                handleClose()
            }
        }
        if (type === 'excludeSelectAll') {
            setAllExcludeChecked([]);
            setIncludeChecked([]);
            const nodes = props.filterType === 'project_stages' ? excludeStagesFilteredNodes : filteredNodes
            nodes?.map((parentVal) => {
                setAllExcludeChecked(oldArray => [...oldArray, parentVal.value]);
                setExcludeChecked(oldArray => [...oldArray, parentVal.value]);
                checkedExcluded.push(parentVal.value)
                if (parentVal && parentVal.children !== undefined) {
                    parentVal.children.map((childVal) => {
                        setAllExcludeChecked(oldArray => [...oldArray, childVal.value]);
                        setExcludeChecked(oldArray => [...oldArray, childVal.value]);
                        checkedExcluded.push(childVal.value)
                        if (childVal && childVal.children !== undefined) {
                            childVal && childVal.children.map((subChildVal) => {
                                setAllExcludeChecked(oldArray => [...oldArray, subChildVal.value]);
                                setExcludeChecked(oldArray => [...oldArray, subChildVal.value]);
                                checkedExcluded.push(subChildVal.value)
                            })
                        }
                    })
                }
            });
            dispatch(updateSelectAllOptions({
                isExcluded, 'filter': filterKey, 'child': props.dataMappings ? props.dataMappings.subFilterType : '',
                isIncludeExclude: props?.dataMappings?.isIncludeExclude, includeKey: props.dataMappings.includeKey, excludeKey: props.dataMappings.excludeKey, "selectedData": checkedExcluded
            }))
        }
        dispatch(updateIncludeExcludeCheckbox(['includeSelectAll', 'includeClearAll'].includes(type) ? checkedIncluded : checkedExcluded))
    }

    // Function to recursively extract values from JSON objects and their children
    const extractValues = (objects) => {
        const values = [];
        objects.forEach(obj => {
            values.push(obj.value);
            if (obj.children?.length > 0) {
                values.push(...extractValues(obj.children));
            }
        });
        return values;
    };

    const expandAll = (e) => {
        e.preventDefault()
        if (expandValues.length < 1) {
            filteredNodes?.map((parentVal) => {
                setExpandValues(oldArray => [...oldArray, parentVal.value]);
                if (parentVal && parentVal.children !== undefined) {
                    parentVal.children.map((childVal) => {
                        setExpandValues(oldArray => [...oldArray, childVal.value]);
                    })
                }
            });
        } else {
            setExpandValues([]);
        }
    };

    useEffect(() => {
        updateStagesFilteredNodes();
        updateSelectedIncludeExcludeCheckbox()
    }, [])

    useEffect(() => {
        filterTree(filterText);
        updateFilterDataBasedOnInputValue(filterText);
    }, [filterText])

    useEffect(() => {
        if (props.filterType === 'project_stages') {
            let updatedArray = _.cloneDeep(filteredNodes);
            updatedArray?.map((parentVal) => {
                parentVal["className"] = ""
                setExpandValues(oldArray => [...oldArray, parentVal.value]);
                if (parentVal && parentVal.children !== undefined) {
                    parentVal.children.map((childVal) => {
                        childVal["className"] = ""
                        setExpandValues(oldArray => [...oldArray, childVal.value]);
                    })
                }
            });
            setFilteredNodes(updatedArray)
        }
    }, [props.filterType])


    function updateStagesFilteredNodes() {
        if (includeStagesFilteredNodes.length > 0 && props.filterType === 'project_stages') {
            const updatedArray = _.cloneDeep(includeStagesFilteredNodes);
            updatedArray?.map((parentVal) => {
                parentVal["className"] = "";
                if (parentVal.label === 'Stopped') {
                    parentVal.showCheckbox = false;
                    if (parentVal && parentVal.children !== undefined) {
                        parentVal.children.map((childVal) => {
                            childVal.showCheckbox = false;
                            childVal["className"] = "";
                        });
                    }
                } else {
                    if (parentVal.label === 'Planning Stages') {
                        if (parentVal && parentVal.children !== undefined) {
                            parentVal.children.sort((a, b) => (a.label !== 'FastPlan') ? 1 : -1)
                        }
                    }
                    parentVal.showCheckbox = true;
                    if (parentVal && parentVal.children !== undefined) {
                        parentVal.children.map((childVal) => {
                            childVal.showCheckbox = true;
                            childVal["className"] = childVal.label === "FastPlan" ? " fastPlan" : "";
                        });
                    }
                }
            });
            setIncludeStagesFilteredNodes(updatedArray);
        }
        if (excludeStagesFilteredNodes.length > 0 && props.filterType === 'project_stages') {
            const updatedArray = _.cloneDeep(excludeStagesFilteredNodes);
            updatedArray?.map((parentVal) => {
                parentVal["className"] = ""
                if (parentVal.label === 'Planning Stages') {
                    parentVal.children.sort((a, b) => (a.label !== 'FastPlan') ? 1 : -1)
                    parentVal.showCheckbox = false;
                    if (parentVal && parentVal.children !== undefined) {
                        parentVal.showCheckbox = false;
                        parentVal.children.map((childVal) => {
                            if (childVal.label !== 'Plans refused') {
                                childVal.showCheckbox = false;
                                childVal["className"] = "";
                            } else {
                                childVal.showCheckbox = true;
                                childVal["className"] = "";
                            }
                        });
                    }
                } else if (parentVal.label === 'Contract Stages') {
                    parentVal.showCheckbox = true;
                    if (parentVal && parentVal.children !== undefined) {
                        parentVal.children.map((childVal) => {
                            childVal.showCheckbox = true;
                            childVal["className"] = "";
                        });
                    }
                } else {
                    parentVal.showCheckbox = false;
                    if (parentVal && parentVal.children !== undefined) {
                        parentVal.children.map((childVal) => {
                            childVal.showCheckbox = true;
                            childVal["className"] = "";
                        });
                    }
                }
            });
            setExcludeStagesFilteredNodes(updatedArray);
        }
    }

    const updateSelectedIncludeExcludeCheckbox = () => {
        if (props.filterType === 'project_geocode' || props.filterType === 'company_geocode') {
            filter.filtersState[searchPersist.appliedContext][props.regionType]?.options?.map((parentVal) => {
                if (parentVal.active === true) {
                    setIncludeChecked(oldArray => [...oldArray, parentVal.value]);
                }
                if (parentVal && parentVal.counties !== undefined) {
                    parentVal.counties.map((childVal) => {
                        if (childVal.active === true) {
                            setIncludeChecked(oldArray => [...oldArray, childVal.value]);
                        }
                    })
                }
            });
        }

        if (props.filterType === 'project_materials') {
            filter.filtersState[searchPersist.appliedContext].materials?.options?.map((parentVal) => {
                if (parentVal.active === true) {
                    setIncludeChecked(oldArray => [...oldArray, parentVal.value]);
                }
                if (parentVal && parentVal.subParent !== undefined) {
                    parentVal.subParent.map((childVal) => {
                        if (childVal.active === true) {
                            setIncludeChecked(oldArray => [...oldArray, childVal.value]);
                        }
                        if (childVal && childVal.children !== undefined) {
                            childVal.children.map((subChildVal) => {
                                if (subChildVal.active === true) {
                                    setIncludeChecked(oldArray => [...oldArray, subChildVal.value]);
                                }
                            })
                        }
                    })
                }
            });
        }

        if (props.filterType === 'categories') {
            filter.filtersState[searchPersist.appliedContext].includeCategories?.options?.map((parentVal) => {
                if (parentVal.active === true) {
                    setIncludeChecked(oldArray => [...oldArray, parentVal.value]);
                }
                if (parentVal && parentVal.subCategories !== undefined) {
                    parentVal.subCategories.map((childVal) => {
                        if (childVal.active === true) {
                            setIncludeChecked(oldArray => [...oldArray, childVal.value]);
                        }
                    })
                }
            });
            filter.filtersState[searchPersist.appliedContext].excludeCategories?.options?.map((parentVal) => {
                if (parentVal.active === true) {
                    setExcludeChecked(oldArray => [...oldArray, parentVal.value]);
                }
                if (parentVal && parentVal.subCategories !== undefined) {
                    parentVal.subCategories.map((childVal) => {
                        if (childVal.active === true) {
                            setExcludeChecked(oldArray => [...oldArray, childVal.value]);
                        }
                    })
                }
            });
        }

        if (props.filterType === 'project_development_types') {
            filter.filtersState[searchPersist.appliedContext].includeDevelopmentTypes?.options?.map((parentVal) => {
                if (parentVal.active === true) {
                    setIncludeChecked(oldArray => [...oldArray, parentVal.value]);
                }
            });
            filter.filtersState[searchPersist.appliedContext].excludeDevelopmentTypes?.options?.map((parentVal) => {
                if (parentVal.active === true) {
                    setExcludeChecked(oldArray => [...oldArray, parentVal.value]);
                }
            });
        }

        if (props.filterType === 'project_roles') {
            filter.filtersState[searchPersist.appliedContext].includeRoles?.options?.map((parentVal) => {
                if (parentVal.active === true) {
                    setIncludeChecked(oldArray => [...oldArray, parentVal.value]);
                }
            });
            filter.filtersState[searchPersist.appliedContext].excludeRoles?.options?.map((parentVal) => {
                if (parentVal.active === true) {
                    setExcludeChecked(oldArray => [...oldArray, parentVal.value]);
                }
            });
        }

        if (props.filterType === 'project_stages') {
            filter.filtersState[searchPersist.appliedContext].includeStages?.options?.map((parentVal) => {
                if (parentVal.active === true) {
                    setIncludeChecked(oldArray => [...oldArray, parentVal.value]);
                }
                if (parentVal && parentVal.children !== undefined) {
                    parentVal.children.map((childVal) => {
                        if (childVal.active === true) {
                            setIncludeChecked(oldArray => [...oldArray, childVal.value]);
                        }
                    })
                }
            });
            filter.filtersState[searchPersist.appliedContext].advanceExcludeStages?.options?.map((parentVal) => {
                if (parentVal.active === true) {
                    setExcludeChecked(oldArray => [...oldArray, parentVal.value]);
                }
                if (parentVal && parentVal.children !== undefined) {
                    parentVal.children.map((childVal) => {
                        if (childVal.active === true) {
                            setExcludeChecked(oldArray => [...oldArray, childVal.value]);
                        }
                    })
                }
            });
        }
    }

    useEffect(() => {
        let tempFilter = filter.filtersState[searchPersist.appliedContext]
        if (tempFilter && tempFilter.options && tempFilter.options.length > 0) {
            tempFilter.options.map(item => {
                if (item.active || item.isChildSelected) {
                    setIsAnyIncludeItemSelected(true)
                }
                if (!item.active) {
                    setAllIncludeItemsSelected(false)
                }
            })
        }

        if (props.excludeFilter && props.excludeFilter.options && props.excludeFilter.options.length > 0) {
            props.excludeFilter.options.map(item => {
                if (item.active || item.isChildSelected) {
                    setIsAnyIncludeItemSelected(true)
                }
                if (props.excludeFilter.title == "excludeStages") {
                    if (item.name !== "Planning Stages" && !item.active) {
                        setAllExcludeItemsSelected(false)
                    }
                } else {
                    if (!item.active) {
                        setAllExcludeItemsSelected(false)
                    }
                }
            })
        }
        clearOnsummaryRemove()
    }, [filter.filtersState])

    useEffect(() => {
        updateStagesFilteredNodes()
    }, [includeStagesFilteredNodes, excludeStagesFilteredNodes])

    const clearOnsummaryRemove = () => {
        let tempFilter = filter.filtersState[searchPersist.appliedContext]
        if (props.filterType === 'project_geocode' && !tempFilter.regions.active) {
            setIncludeChecked([])
            setExcludeChecked([])
        }
        if (props.filterType === 'company_geocode' && !tempFilter.companyRegions.active) {
            setIncludeChecked([])
            setExcludeChecked([])
        }
        if (props.filterType === 'categories' && !tempFilter.excludeCategories.active && !tempFilter.includeCategories.active) {
            setIncludeChecked([])
            setExcludeChecked([])
        }
        if (props.filterType === 'project_development_types' && !tempFilter.excludeDevelopmentTypes.active && !tempFilter.includeDevelopmentTypes.active) {
            setIncludeChecked([])
            setExcludeChecked([])
        }
        if (props.filterType === 'project_stages' && !tempFilter.includeStages.active && !tempFilter.excludeStages.active && !tempFilter.advanceExcludeStages.active) {
            setIncludeChecked([])
            setExcludeChecked([])
        }
        if (props.filterType === 'project_roles' && !tempFilter.includeRoles.active && !tempFilter.excludeRoles.active) {
            setIncludeChecked([])
            setExcludeChecked([])
        }
        if (props.filterType === 'project_materials' && !tempFilter.materials.active) {
            setIncludeChecked([])
            setExcludeChecked([])
        }
    }

    const onIncludeCheck = (newChecked, targetNode) => {
        if (targetNode.checked) {
            // Filter out the values from includeChecked that are not present in newChecked
            const valuesToAdd = includeChecked.filter(value => !newChecked.includes(value));
            // Push the filtered values to newChecked array
            valuesToAdd.forEach(value => newChecked.push(value));
        }
        setIncludeChecked(Array.isArray(newChecked) ? newChecked : newChecked?.children)
        let checkedValues = Array.isArray(newChecked) ? newChecked : newChecked?.children
        dispatch(updateIncludeExcludeCheckbox(Array.isArray(newChecked) ? newChecked : newChecked?.children))
        dispatch(setIncludeCheckboxLength(Array.isArray(newChecked) ? newChecked?.length : newChecked?.children?.length))
        let exludedArray = excludeChecked.reduce((acc, curr) => {
            if (!newChecked.includes(curr.toString())) {
                acc.push(curr);
            }
            return acc;
        }, []);
        setExcludeChecked(exludedArray)
        dispatch(setExcludeCheckboxLength(exludedArray.length))
        let nodes
        if (targetNode.isChild) {
            if (targetNode.checked) {
                nodes = targetNode.parent.children?.filter(item => (item.value).toString() === targetNode.value.toString())
            }
            else {
                let arr = targetNode.parent.children.filter(function (item) {
                    return newChecked.indexOf(item.value) === -1;
                });

                nodes = arr.filter(item => item.subscribed)
            }
        }
        else {
            nodes = filteredNodes
        }
        if (props.filterType === "project_geocode" || props.filterType === "company_geocode") {
            handleRegionAndCountiesFilter(targetNode, targetNode.isChild, nodes)
        }
        else if (props.filterType === "project_materials") {
            handleMaterialFilter(targetNode, checkedValues)
        }
        else {
            handleFilter(targetNode, targetNode.isChild, false, nodes, checkedValues)
        }
    };

    function handleMaterialFilter(e, checkedValues) {
        let payload = {
            value: e.value,
            active: e.checked,
            selectedData: checkedValues
        }
        dispatch(updateMaterialFilterItem(payload))

        // props.fetchEntityCounts()
        // props.variablesHandler("materials", props.parentModel)
    }

    function handleRegionAndCountiesFilter(targetNode, isChild, item) {
        let updatedChildren = []
        let isActive = targetNode.checked
        if (item.children != undefined) {
            item.children.filter(subItem => subItem.subscribed).map(subItem => {
                updatedChildren.push(subItem)
            })
            isActive = item.children.filter(subItem => subItem.subscribed).length > updatedChildren.length
                ? targetNode.checked : false
        }
        let filteredItem = item?.filter(i => i.label === targetNode.label)
        if (filteredItem[0]?.subscribed) {
            if (!targetNode.checked) {
                // this.setState({ selectAll: targetNode.checked, isAllChildSelected: isActive })
            }
            const childrenValues = filteredItem?.length > 0 ? filteredItem[0]?.children?.map(child => child.value) : []
            dispatch(updateFilterItems({ 'filter': props.regionType, 'child': 'counties', 'value': targetNode.value, 'active': targetNode.checked, 'isChild': isChild, 'parentIdForChild': targetNode.parent.value, "selectedData": childrenValues }))
            // props.fetchEntityCounts({ initTags: props.initTags, badgeCount: props.handleBadgeCount })
        }
    }

    function handleFilter(event, isChild, isExcluded, item, checkedValues) {
        let updatedChildren = []
        let isActive = event.checked
        if (item != undefined && item[props.dataMappings.subFilterType] != undefined) {
            item[props.dataMappings.subFilterType].filter(subItem => subItem.subscribed).map(subItem => {
                if (subItem.active) {
                    updatedChildren.push(subItem)
                }
            })
            isActive = item[props.dataMappings.subFilterType].filter(subItem => subItem.subscribed).length > updatedChildren.length
                ? event.checked : false
        }
        let filteredItem = item?.filter(i => i.label === event.label)
        if (filteredItem[0]?.subscribed) {
            let finalCheckedValues = []
            let filterKey = props.dataMappings ? isExcluded ? props.dataMappings.excludeKey : props.dataMappings.includeKey : ''
            if (checkedValues?.length > 0) {
                finalCheckedValues = checkedValues.flatMap(item => item.toString().split(',').map(Number))
            }
            dispatch(updateFilterItems({
                isExcluded, isIncludeExclude: props.dataMappings.isIncludeExclude, includeKey: props.dataMappings.includeKey, excludeKey: props.dataMappings.excludeKey, 'filter': filterKey, 'child': props.dataMappings ? props.dataMappings.subFilterType : '',
                'value': event.value, 'active': isActive, 'isChild': isChild, 'parentIdForChild': event.value, "selectedData": finalCheckedValues
            }))
            // props.fetchEntityCounts({ initTags: props.initTags, badgeCount: props.handleBadgeCount })
        }
    }

    const onExcludeCheck = (newChecked, targetNode) => {
        // Filter out the values from includeChecked that are not present in newChecked
        if (targetNode.checked) {
            const valuesToAdd = excludeChecked.filter(value => !newChecked.includes(value));
            // Push the filtered values to newChecked array
            valuesToAdd.forEach(value => newChecked.push(value));
        }
        setExcludeChecked(Array.isArray(newChecked) ? newChecked : newChecked?.children);
        let checkedValues = Array.isArray(newChecked) ? newChecked : newChecked?.children
        dispatch(updateIncludeExcludeCheckbox(Array.isArray(newChecked) ? newChecked : newChecked?.children))
        dispatch(setExcludeCheckboxLength(Array.isArray(newChecked) ? newChecked?.length : newChecked?.children?.length))
        let includeArray = includeChecked.reduce((acc, curr) => {
            if (!newChecked.includes(curr.toString())) {
                acc.push(curr);
            }
            return acc;
        }, []);
        setIncludeChecked(includeArray)
        dispatch(setIncludeCheckboxLength(includeArray.length))
        let nodes
        if (targetNode.isChild && targetNode.value) {
            if (targetNode.checked) {
                nodes = targetNode.parent.children?.filter(item => (item.value).toString() === targetNode.value.toString())
            }
            else {
                let arr = targetNode.parent.children.filter(function (item) {
                    return newChecked.indexOf(item.value) === -1;
                });

                nodes = arr.filter(item => item.subscribed)
            }
        }
        else {
            nodes = filteredNodes
        }
        if (props.filterType === "project_geocode" || props.filterType === "company_geocode") {
            handleRegionAndCountiesFilter(targetNode, targetNode.isChild, nodes)
        }
        else if (props.filterType === "project_materials") {
            handleMaterialFilter(targetNode, checkedValues)
        }
        else {
            handleFilter(targetNode, targetNode.isChild, true, nodes, checkedValues)
        }
    };

    const handleRequestDataToggle = (event) => {
        setRequestDataModal(!requestDataModal)
        if (event.target.innerText.toLowerCase().indexOf("request data") !== -1)
            logEventOnRequestData()
    }

    const logEventOnRequestData = () => {
        let payload = { filterName: props.filterTitle }
        logEventForRequestData(payload, dispatch)
    }

    const handlePrimaryOnlyChange = (e) => {
        dispatch(updatePrimaryCategoriesOnlyFlag(e.target.checked))
        if (props.filter.active ||
            props.filter.isChildSelected ||
            props.excludeFilters.active ||
            props.excludeFilters.isChildSelected) {
            let isExcluded = props.filter.active || props.filter.isChildSelected ? false : true
            let filterKey = props.dataMappings ? isExcluded ? props.dataMappings.excludeKey : props.dataMappings.includeKey : ''
            props.variablesHandler(filterKey, props.parentModel)
        }
    }

    const showHideUnsubscribed = () => {
        if (props.filterType === 'project_stages') {
            if (includeStagesFilteredNodes.length > 0) {
                const updatedArray = _.map(cloneDeep(includeStagesFilteredNodes), (node) => {
                    if (node.hasOwnProperty('subscribed')) {
                        if (!node.subscribed) {
                            node.className = !node.subscribed && node.className === 'hide' ? "" : 'hide'
                            let childnode = _.map(cloneDeep(node.children), (item) => {
                                if (item.label === 'FastPlan') {
                                    if (!item.subscribed) {
                                        item.className = !item.subscribed && item.className === 'hide fastPlan' ? ' fastPlan' : 'hide fastPlan'
                                    }
                                }
                                else {
                                    if (!item.subscribed) {
                                        item.className = !item.subscribed && item.className === 'hide' ? '' : 'hide'
                                    }
                                }
                                return item
                            })
                            node.children = childnode
                        }
                        else {
                            let childnode = _.map(cloneDeep(node.children), (item) => {
                                if (item.label === 'FastPlan') {
                                    if (!item.subscribed) {
                                        item.className = !item.subscribed && item.className === 'hide fastPlan' ? ' fastPlan' : 'hide fastPlan'
                                    }
                                }
                                else {
                                    if (!item.subscribed) {
                                        item.className = !item.subscribed && item.className === 'hide' ? '' : 'hide'
                                    }
                                }
                                return item
                            })
                            node.children = childnode
                        }
                    }
                    return node
                });
                setIncludeStagesFilteredNodes(updatedArray);
            }
            if (excludeStagesFilteredNodes.length > 0) {
                const updatedArray = _.map(cloneDeep(excludeStagesFilteredNodes), (node) => {
                    if (node.hasOwnProperty('subscribed')) {
                        if (!node.subscribed) {
                            node.className = !node.subscribed && node.className === 'hide' ? "" : 'hide'
                            let childnode = _.map(cloneDeep(node.children), (item) => {
                                if (!item.subscribed) {
                                    item.className = !item.subscribed && item.className === 'hide' ? "" : 'hide'
                                }
                                return item
                            })
                            node.children = childnode
                        }
                        else {
                            let childnode = _.map(cloneDeep(node.children), (item) => {
                                if (!item.subscribed) {
                                    item.className = !item.subscribed && item.className === 'hide' ? "" : 'hide'
                                }
                                return item
                            })
                            node.children = childnode
                        }
                    }
                    return node
                });
                setExcludeStagesFilteredNodes(updatedArray);
            }
        }

        let profileData = _.map(cloneDeep(filteredNodes), (node) => {
            if (node.hasOwnProperty('subscribed')) {
                if (!node.subscribed) {
                    node.className = !node.subscribed && node.className === 'hide' ? "" : 'hide'
                    let childnode = _.map(cloneDeep(node.children), (item) => {
                        if (!item.subscribed) {
                            item.className = !item.subscribed && item.className === 'hide' ? "" : 'hide'
                        }
                        return item
                    })
                    node.children = childnode
                }
                else {
                    let childnode = _.map(cloneDeep(node.children), (item) => {
                        if (!item.subscribed) {
                            item.className = !item.subscribed && item.className === 'hide' ? "" : 'hide'
                        }
                        return item
                    })
                    node.children = childnode
                }
            }
            return node
        });
        setFilteredNodes(profileData)
        props.showAll();
    }

    const handleSelectAllFavRoles = (selectStatus, isExcluded) => {
        let favouriteRoles = settings.preferences.favourite_roles,
            filterKey = props.dataMappings ? isExcluded ? props.dataMappings?.excludeKey : props.dataMappings?.includeKey : ''
        if (isExcluded) {
            setExcludeChecked(favouriteRoles);
            const filteredArray = includeChecked.filter(element => !favouriteRoles.includes(element));
            setIncludeChecked(filteredArray)
        } else {
            setIncludeChecked(favouriteRoles)
            const filteredArray = excludeChecked.filter(element => !favouriteRoles.includes(element));
            setExcludeChecked(filteredArray)
        }
        dispatch(updateSelectAllFavourites({
            isExcluded, 'filter': filterKey, 'child': props?.dataMappings ? props?.dataMappings?.subFilterType : '',
            isIncludeExclude: props?.dataMappings?.isIncludeExclude,
            includeKey: props.dataMappings?.includeKey, excludeKey: props.dataMappings?.excludeKey,
            selectStatus, favourites: favouriteRoles
        }))
    }

    return (
        <>
            <Card block className="pl-0 pr-0 pt-0 pb-0 border-0 include-exclude-grid">
                <div className="subsearch-filters catering-filter-block">
                    <div className="mb-0">
                        <fieldset className="form-group mb-0 pb-0">
                            <div className="textfield-wrapper border-0">
                                <input
                                    className="filter-text"
                                    placeholder="Search..."
                                    type="text"
                                    value={filterText}
                                    onChange={onFilterChange}
                                />
                                <Button className={`clear-textfield ${filterText !== "" ? 'display-block' : 'display-none'}`} onClick={handleClose}>x</Button>
                            </div>
                            <div className="select-toggle float-none clearfix">
                                <Col md={8} className=" keep-left primary-category-section">
                                    {props.commontext === true && (
                                        <div className="show primary-category">
                                            <div className="check-box-button">
                                                <input id="primary-category-only" type="checkbox" className="form-check-input"
                                                    onChange={(e) => handlePrimaryOnlyChange(e)} checked={filter?.filtersState[searchPersist.appliedContext]?.primaryCategoriesOnly}
                                                    value="primaryCategoriesOnly" />
                                                <label for="primary-category-only" className="">Primary category only</label>
                                            </div>
                                        </div>
                                    )
                                    }
                                    {props.filterType !== 'project_development_types' ? <a className="expand-collpse-link" name="expandAll" onClick={(e) => { expandAll(e) }}>{expandValues.length > 0 ? 'Collapse all' : 'Expand all'}</a> : ('')}
                                    <p className="hide text-danger m-0">Please select atleast one category</p>
                                </Col>
                                <Col md={props.filter !== true ? '2' : '4'} className={`p-0 keep-left ${props.filter !== true ? 'text-left include-all-checkbox' : 'include-all-checkbox-deafult'}`}>
                                    <p className={`m-0 d-block w-100 ${props.filter !== true ? 'keep-left' : ''}`}>{props.filter !== true ? 'Include' : ''}</p>
                                    <ButtonDropdown className={props.filter !== true ? 'keep-left' : ''} isOpen={includedropdownOpen} toggle={toggleInclude}>
                                        <DropdownToggle caret size="lg" className="no-background include-exclude-all">
                                            <div className={`check-box-button no-margin ${!allIncludeItemsSelected && isAnyIncludeItemSelected ? 'child-selected' : ''}`}>
                                                <input name="IncludeSelectAll" id={`IncludeSelectAll-${props.filterType}`} type="checkbox" className="form-check-input"
                                                    checked={props?.includeFilters?.isAllSelected}
                                                    onChange={(e) => e.target.checked ? handleCustomCheckbox(true, false, e, 'includeSelectAll') : handleCustomCheckbox(false, false, e, 'includeClearAll')} />
                                                <label
                                                    for={`IncludeSelectAll-${props.filterType}`}
                                                    className="form-check-label">
                                                </label>
                                            </div>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={(e) => handleCustomCheckbox(true, false, e, 'includeSelectAll')}>Select all</DropdownItem>
                                            {props.filterType === 'project_roles' && settings?.preferences?.favourite_roles && settings.preferences.favourite_roles.length > 0 ? <DropdownItem onClick={() => handleSelectAllFavRoles(false, false)} className="select-and-clear">Select favourites</DropdownItem> : ""}
                                            <DropdownItem onClick={(e) => handleCustomCheckbox(false, false, e, 'includeClearAll')}>Clear all</DropdownItem>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </Col>
                                {props.filter !== true && (
                                    <Col md={2} className=" keep-left text-left p-0 exclude-all-checkbox">
                                        <p class="m-0 d-block w-100 text-black">Exclude</p>
                                        <ButtonDropdown className="keep-left" isOpen={excludedropdownOpen} toggle={toggleExclude} {...props}>
                                            <DropdownToggle caret size="lg" className="no-background include-exclude-all">
                                                <div className={`check-box-button no-margin ${!allExcludeItemsSelected && isAnyExcludeItemSelected ? 'child-selected' : ''}`}>
                                                    <input name="ExcludeSelectAll" id={`ExcludeSelectAll-${props.filterType}`} type="checkbox" className="form-check-input"
                                                        checked={props?.excludeFilters?.isAllSelected}
                                                        onClick={(e) => e.target.checked ? handleCustomCheckbox(true, true, e, 'excludeSelectAll') : handleCustomCheckbox(false, true, e, 'excludeClearAll')} />
                                                    <label
                                                        for={`ExcludeSelectAll-${props.filterType}`}
                                                        className="form-check-label">
                                                    </label>
                                                </div>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={(e) => handleCustomCheckbox(true, true, e, 'excludeSelectAll')}>Select all</DropdownItem>
                                                {props.filterType === 'project_roles' && settings?.preferences?.favourite_roles && settings.preferences.favourite_roles.length > 0 ? <DropdownItem onClick={() => handleSelectAllFavRoles(true, true)} className="select-and-clear">Select favourites</DropdownItem> : ""}
                                                <DropdownItem onClick={(e) => handleCustomCheckbox(false, true, e, 'excludeClearAll')}>Clear all</DropdownItem>
                                            </DropdownMenu>
                                        </ButtonDropdown>
                                    </Col>
                                )
                                }
                            </div>
                            <div className="withcollapsCheckboxes">
                                <div id={`${props.filterType}-filters`} className="tree-filter">
                                    <Card block className="p-0">
                                        <div className="include-child border-0">
                                            <ColoredScrollbars>
                                                {
                                                    props.filterType === 'project_stages' ?
                                                        <Row className='includeExcludeDiv'>
                                                            <Col md={11} className='includeDiv'>
                                                                <Include
                                                                    expandValue={expandValue}
                                                                    filteredNodes={includeStagesFilteredNodes}
                                                                    checkvalue={allIncludeChecked}
                                                                    expandValues={expandValues}
                                                                    checked={includeChecked}
                                                                    onCheck={onIncludeCheck}
                                                                />
                                                            </Col>
                                                            <Col md={1} className='excludeDiv'>
                                                                <Exclude
                                                                    includExpand={expandedValue}
                                                                    filteredNodes={excludeStagesFilteredNodes}
                                                                    checkvalue={allExcludeChecked}
                                                                    expandValues={expandValues}
                                                                    checked={excludeChecked}
                                                                    onCheck={onExcludeCheck}
                                                                />
                                                            </Col>
                                                        </Row> :
                                                        <Row className='includeExcludeDiv'>
                                                            <Col md={11} className='includeDiv'>
                                                                <Include
                                                                    expandValue={expandValue}
                                                                    filteredNodes={filteredNodes}
                                                                    checkvalue={allIncludeChecked}
                                                                    expandValues={expandValues}
                                                                    checked={includeChecked}
                                                                    onCheck={onIncludeCheck}
                                                                // handleFilterTypeClick={(e) => handleFilter(e, props.dataMappings.subFilterType, false, filteredNodes)}
                                                                />
                                                            </Col>
                                                            {
                                                                props.filterType === 'project_geocode' || props.filterType === "company_geocode" || props.filterType === "project_materials" ? ('') : (
                                                                    <>
                                                                        <Col md={1} className='excludeDiv'>
                                                                            <Exclude
                                                                                includExpand={expandedValue}
                                                                                filteredNodes={filteredNodes}
                                                                                checkvalue={allExcludeChecked}
                                                                                expandValues={expandValues}
                                                                                checked={excludeChecked}
                                                                                onCheck={onExcludeCheck}
                                                                            />
                                                                        </Col>
                                                                    </>
                                                                )
                                                            }
                                                        </Row>
                                                }
                                            </ColoredScrollbars>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div >
            </Card >
            {
                !props.allSubscribed && props.allSubscribed !== undefined ?
                    <div className="show-all-criteria px-3 py-3 mt-1">
                        {!props.showSubscribed ?
                            <div className="d-flex justify-content-between">
                                <span>
                                    <a className="show-criteria" onClick={showHideUnsubscribed}>Show {props.filterTitle} outside of your subscription criteria</a>
                                </span>
                            </div> :
                            <div className="d-flex justify-content-between">
                                <div className="d-inline-flex justify-content-start">
                                    <span>Showing all {props.filterTitle}</span>
                                    <span><a className="show-criteria ms-4" onClick={showHideUnsubscribed}>Hide</a></span>
                                </div>
                                <div>
                                    <button className="primary-btn btn" onClick={handleRequestDataToggle}>Request data</button>
                                </div>
                                {
                                    requestDataModal ?
                                        <RequestDataModal
                                            requestDataModalShow={requestDataModal}
                                            handleRequestDataToggle={handleRequestDataToggle}
                                            filterName={props.filterType}
                                            filterTitle={props.filterTitle}
                                        /> : ("")
                                }
                            </div>
                        }
                    </div> : ("")
            }
        </>
    )
}

export default IncludeExcludeGrid;