import ReactMapboxGl from 'react-mapbox-gl';
export const MIN_KM = 25
export const MIN_MILES = 25
export const ABI_IOS_APP = "https://apps.apple.com/us/app/barbour-abi/id1449550538?ls=1"
export const ABI_PLAY_APP = "https://play.google.com/store/apps/details?id=com.barbour_abi.app"
export const ANDROID_PROJECT_LOCATOR_URL = 'https://play.google.com/store/apps/details?id=com.enhancedBDM.projectlocator&hl=en'
export const IOS_PROJECT_LOCATOR_URL = 'https://itunes.apple.com/us/app/project-locator/id1259058754?ls=1&mt=8'
export const ABILIVE = "https://www.barbour-abi.com/"
export const PRODURL = "https://app.barbour-abi.com/"
export const STAGINGURl = "https://web2.staging.ipdbarbourabi.net/"
export const ABI_URLS = ['127.0.0.1', 'web2.integration.ipdbarbourabi.net', 'web2.testing.ipdbarbourabi.net', 'web2.uat.ipdbarbourabi.net', 'web2.staging.ipdbarbourabi.net', 'web2.prod.ipdbarbourabi.net', 'app.barbour-abi.com'];
export const PROJECT_LOCATOR_URLS = ['web2-projectlocator.integration.ipdbarbourabi.net', 'web2-projectlocator.testing.ipdbarbourabi.net', 'web2-projectlocator.uat.ipdbarbourabi.net', 'web2-projectlocator.staging.ipdbarbourabi.net', 'web2-projectlocator.prod.ipdbarbourabi.net', 'app.projectlocator.co.uk'];
export const LINKEDIN = "https://www.linkedin.com/company/barbour-abi/"
export const TWITTER = "https://twitter.com/BarbourABI"
export const INSTAGRAM = "https://www.instagram.com/barbour_abi/"
export const YOUTUBE = "https://www.youtube.com/BarbourABI"
export const FACEBOOK = "https://www.facebook.com/BarbourABI"
export const ABIPHONE = "0151 353 3500"
export const VIDEO_EXTENSION = ['mp4', 'mp3', 'webm', 'ogg']
export const MAPBOX_URL = 'https://api.mapbox.com/geocoding/v5/mapbox.places/'

export const PROD_DOMAIN = ['app.projectlocator.co.uk', 'app.barbour-abi.com']
export const UAT_DOMAIN = ['127.0.0.1', 'localhost', 'web2.integration.ipdbarbourabi.net', 'web2.testing.ipdbarbourabi.net', 'web2.uat.ipdbarbourabi.net', 'web2.staging.ipdbarbourabi.net', 'web2.prod.ipdbarbourabi.net']

export const PROJECT_ABI_URL = "%2Fstatic%2Fmedia%2Fabi-project-pin@2x-v2.png"
export const COMPANY_ABI_URL = "%2Fstatic%2Fmedia%2Fabi-company-pin@2x-v2.png"
export const PROJECT_IMG_URL = "https://barbourabi-production-platform-web-images.s3.eu-west-1.amazonaws.com"
export const ABI_LOGO = "/static/barbourabi-logo-new.svg"
export const STATIC_IMAGE = "/static/media/map-placeholder.jpg"

export const MAPBOX_TOKEN = "pk.eyJ1IjoiYmFyYm91cmFiaSIsImEiOiJjanZ2djY3dWsxdmZ3NDhscXYxZXBoY3NmIn0.EwT0hA50gvP8cT4BD896Eg"
export const Map = ReactMapboxGl({ accessToken: MAPBOX_TOKEN })
export const MAP_STYLE_URL = "https://api.mapbox.com/styles/v1/barbourabi/cjxq4oi6q2ul81co71uz3nr92/static/url-"

export const YEAR = 365
export const MONTH = 30
export const EVENT_TYPE = [
    { label: 'Call', value: 'Call' },
    { label: 'Email', value: 'Email' },
    { label: 'Online meeting', value: 'Online meeting' },
    { label: 'Face to face meeting', value: 'Face to face meeting' },
    { label: 'Task', value: 'Task' }]

export const EVENT_PRIORITY = ['High', 'Normal', 'Low']

export const EVENT_STATUS = [
    { label: 'Not started', value: 'Not started' },
    { label: 'In progress', value: 'In progress' },
    { label: 'Completed', value: 'Completed' },
    { label: 'Cancelled', value: 'Cancelled' },
    { label: 'Delayed', value: 'Delayed' }]

export const EVENT_REMINDER = [
    { label: 'None', value: 'none' },
    { label: 'At the time of event', value: '0' },
    { label: '5 mins before', value: '5' },
    { label: '10 mins before', value: '10' },
    { label: '15 mins before', value: '15' },
    { label: '30 mins before', value: '30' },
    { label: '1 hour before', value: '60' },
    { label: '2 hours before', value: '120' },
    { label: '1 day before', value: '1440' },
    { label: '2 days before', value: '2880' },
    { label: '1 week before', value: '10080' },
]

export const EVENT_SORT_ORDER = ['Start date', 'Due date', 'Priority', 'Created']
export const CALENDAR_DEFAULT_LIMIT = 50
export const DEFAULT_LIMIT = 50
export const MAX_PDF_DOWNLOAD_LIMIT = 50
export const CUSTOMER_TYPE = {
    NORMAL_CUSTOMER: 1,
    TRIAL_CUSTOMER: 2
}
export const PROJECT_SEARCH_FIELDS = "project_latitude,project_longitude,project_id,project_title,project_value,project_value_estimated,project_site3,project_last_published,project_planning_stage,project_contract_stage,project_postcode,favourite,read,project_first_published,project_town_postcode,project_image_url,project_image_source_url,project_opening_display_15,project_start_min,project_finish_max,project_build_phase,project_usage,project_enabling_start,project_framework_display,project_is_framework,project_framework_description,project_distance,company_distance,shares_count,tags_count,notes_count,project_start_display,project_finish_display,mail,mailed_on,archive,project_framework,project_site_progression,project_stopped,project_contract_stage_display,project_planning_stage_display,project_stopped_display,project_categories,role_11_company_names"
export const COMPANY_SEARCH_FIELDS = "company_latitude,company_longitude,company_id,company_name,company_address1,company_address2,company_address3,company_address4,company_postcode,company_website,company_phone,company_distance,max_project_last_published,count_project_id,any_project_value_estimated,sum_project_value,favourite,read,activity_date,company_group_type,any_project_value_estimated,company_linkedin,project_framework_display,project_is_framework,project_framework_description,project_distance,shares_count,tags_count,notes_count,mail,mailed_on,company_phone_tps"
export const PEOPLE_SEARCH_FIELDS = "person_email,person_first_name,person_id,person_job_title,person_last_name,person_title,company_name,company_address1,company_address2,company_address3,company_address4,company_postcode,company_phone,person_phone,company_id,company_name,company_location,company_geocode,company_distance,person_mobile,person_linkedin,project_framework_display,project_is_framework,project_framework_description,person_phone_tps,person_mobile_tps,company_phone_tps"
export const EVENTS_FIELD_QUERY = 'project_id,project_title,archive,project_value,project_site3,project_postcode,project_value_estimated,project_last_published'
export const EVENTS_COMPANY_FIELD_QUERY = "company_id,company_name,company_phone,company_address4,company_postcode"
export const PEOPLE_FIELD_QUERY = 'person_first_name,person_last_name,person_job_title,person_phone,person_mobile,person_email,person_id,person_phone_tps,person_mobile_tps,person_added, person_linkedin'
export const ROLES_FIELD_QUERY = 'company_id,company_name,role_code,company_address1,company_phone,company_email,company_location,project_last_published,company_email,company_postcode,company_address2,company_address3,company_address4,person_id,person_title,person_email,person_first_name,person_last_name,person_phone,favourite,read,company_roles,company_website,company_phone,shares_count,tags_count,notes_count,person_job_title,company_latitude,company_longitude,company_phone_tps,person_phone_tps,role_added'
export const PROJECT_FIELD_QUERY = 'project_value,project_title,project_storeys_above,project_storeys_below,project_start_display,project_site_area,project_site4,project_site3,project_site2,project_site1,project_scheme,project_researcher_full_name,project_primary_sector_display,project_primary_category_display,project_plans_submitted,project_planning_stage_display,project_planning_reference,project_longitude,project_latitude,project_last_published,project_id,project_geocode,project_geocode_display,project_floor_area,project_finish_display,project_duration,project_development_types_display,project_contract_stage_display,project_categories_display,project_status,project_contract_type_display,project_funding_display,project_plan_area,project_structures,project_houses,project_hotel_beds,project_hospital_beds,project_student_beds,project_carehome_beds,project_sheltered_beds,project_net_retail_floor,project_gross_retail_floor,project_next_recall,project_tender_close_display,project_two_bed,project_one_bed,project_three_bed,project_four_bed,project_five_bed,project_units,project_planning_url,project_materials_display,project_parking,project_volume,project_linear,project_postcode,favourite,project_value_estimated,project_first_published,project_image_url,project_researcher_image,project_image_source_url,project_last_recall,project_covers,project_kitchen_floor_area,project_start_min,project_finish_max,project_build_phase,project_timing_projected,project_usage_display,project_framework_description,mail,mailed_on,archive,project_site_progression,shares_count,tags_count,notes_count,project_images,project_materials_text,project_stopped_display,project_flats,calendar_events_count,project_last_call,project_land_type_display,project_last_checked,role_code_display,project_planning_portal_reference,project_planning_portal_submitted,project_categories,project_categories_text,read'
export const USER_FIELD_QUERY = 'tags_count,notes_count,mailed,favourite,calendar_events_count,shares_count'
export const ADMIN_LICENSE = [1072, 1073, 1074]
export const FIELD_SALES_LICENSE = [1060, 1061, 1062, 1063, 1065, 1067]
export const DEVELOPER_LICENSE = [1075, 1077, 1078, 1079, 1083]
export const INSIDE_SALES_LICENSE = [1064, 1066]
export const EXPORT_LICENSE = [1080, 1081]
export const CONSTRUCTION_HUB_LICENSE = [1076]
export const CALENDAR_LICENSE = [1082]
export const WEB_APP_LICENSE = [1064]
export const ROLES_SEARCH_FIELDS = 'role_code,project_id,project_title,project_last_published,project_status,project_value,project_site3,project_contract_stage,project_planning_stage,project_stages,project_value_estimated,favourite,read,project_latitude,project_longitude,project_opening_display_15,shares_count,tags_count,notes_count,project_start_display,project_finish_display,project_distance,archive,mail,mailed_on,project_planning_stage_display,project_contract_stage_display,role_11_company_names'
export const COMPANY_FIELD_QUERY = "company_id,count_project_id,sum_project_value,company_address1,company_address2,company_address3,company_address4,company_email,company_fax,company_fax_fps,company_latitude,company_longitude,company_name,company_phone,company_phone_tps,company_postcode,company_website,favourite,max_project_last_published,project_value_estimated,company_linkedin,mail,mailed_on,company_registration_number,company_registration_url,shares_count,tags_count,notes_count,calendar_events_count,read"
export const PROJECT_FIELDS = 'role_code,project_latitude,project_longitude,project_id,project_title,project_value,project_value_estimated,project_site3,project_last_published,project_distance,project_planning_stage,project_contract_stage,favourite,read,activity_date,shares_count,tags_count,notes_count,project_start_display,project_finish_display,archive,role_11_company_names,project_planning_stage_display,project_stage_display'
export const PEOPLE_FIELDS = "person_email,person_first_name,person_id,person_job_title,person_mobile,person_phone,person_last_name,company_name,company_email,company_phone,company_address1,company_address2,company_address3,company_address4,company_postcode,company_latitude,company_longitude,company_location,company_id,person_linkedin,company_phone_tps,person_phone_tps,person_mobile_tps"
export const MIN_UNARCHIVE_PROJECT = 20
export const ENTITY_TYPE = {
    PROJECT: 'project',
    COMPANY: 'company',
    PEOPLE: 'people'
}
export const ARCHIVE_DEFAULT_LIMIT = 250
export const ARCHIVE_RECORD_THRESHOLD = 1000
export const NON_ARCHIVE_RECORD_THRESHOLD = 200
export const COMPANY_GROUP_PROJECT_FIELDS = 'company_latitude,company_longitude,company_id,company_name,company_address1,company_address2,company_address3,company_address4,company_postcode,company_website,company_phone,company_distance,max_project_last_published,count_project_id,any_project_value_estimated,sum_project_value,favourite,read,activity_date,company_group_type,any_project_value_estimated,company_linkedct_latitude,project_longitude,project_latitude,project_id,project_title,project_value,project_value_estimated,project_site3,project_last_published,project_planning_stage,project_planning_stage_display,project_contract_stage,project_postcode,favourite,read,project_first_published,project_town_postcode,project_image_url,project_image_source_url,project_opening_display_15,project_start_min,project_finish_max,project_build_phase,project_usage,project_enabling_start,project_framework_display,project_is_framework,project_framework_description,project_distance,company_distance,shares_count,tags_count,notes_count,project_start_display,project_finish_display,mail,mailed_on,archive,project_framework,project_site_progression,project_stopped,role_11_company_names'
export const MIN_PROJECTS_REQUIRED_FOR_DISPLAY = 50
export const PERCENTAGE_EXTRA_FOR_SUBSEQUENT_CALLS = 100
export const MAX_LIMIT_FOR_API_CALL = 500
export const MAX_API_CALLS = 20
export const HISTORY_COMPANY_FIELDS = 'company_id,company_name,company_address1,company_address2,company_address3,company_address4,company_postcode,company_website,company_phone,favourite,read,activity_date,company_group_type,company_linkedin,company_distance, company_latitude, company_longitude,max_project_last_published,shares_count,tags_count,notes_count,company_phone_tps,count_project_id,sum_project_value'
export const WEEK = 7
export const SIGNOUT = 'user/signOut'
export const STORAGE_KEY = 'persist:root'
export const MYLEADS_PREVNEXT_LIST = ['projectHierarchicalMyLeadsFavourites', 'projectHierarchicalMyLeadsHistory', 'projectHierarchicalMyLeadsTags', 'projectHierarchicalMyLeadsShares', 'projectHierarchicalMyLeadsArchive',
    'myLeadsTags', 'myLeadsShares', 'myLeadsUpdates', 'myLeadsFavourites', 'myLeadsHistory', 'myLeadsArchive']
export const MYLEADS_PREVNEXT_HIERARCHY_LIST = ['projectHierarchicalMyLeadsFavourites', 'projectHierarchicalMyLeadsHistory', 'projectHierarchicalMyLeadsTags', 'projectHierarchicalMyLeadsShares', 'projectHierarchicalMyLeadsArchive']
export const DEFAULT_EXPORT_TEMPLATE_ID = 3
export const STREAM_ID_PROD = "6711024614"
export const STREAM_ID_STAGING = "6711017291"
export const STREAM_NAME_PROD = "Web"
export const STREAM_NAME_STAGING = "Barbour ABI Test"
export const MEASUREMENT_ID_PROD = "G-GK1G482KRT"
export const MEASUREMENT_ID_STAGING = "G-QNPECG5VQ4"